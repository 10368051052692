import React from "react";
import { Typography, Paper } from "@mui/material";
import useCustomStyles from "../styles/customStyles";

function About(props) {
  const customClasses = useCustomStyles();
  return (
    <div className={customClasses.root}>
      <Paper className={customClasses.headingPaper}>
        <Typography variant="heading1">About ceylontamils.com</Typography>
        <div>
          <Typography variant="bodyText">
            This website attempts to collate the genealogies legends and histories of
            Tamils whose ancestors originated in Sri Lanka, formerly Ceylon. It has been
            developed and maintained on a volunteer basis since 2003 by a small team of
            volunteers, to help family members establish their relationship to each other.
          </Typography>
          <Typography variant="bodyText">
            The presence of an individual or family on this site does not indicate that
            they are, or identify as being, a member of the Ceylon Tamil community but are
            linked in some way to people who consider themselves Ceylon Tamils.
          </Typography>
          <Typography variant="bodyText">
            <p>
              The data in this database is no longer accessible publicly due to privacy
              requirements.
            </p>
          </Typography>
        </div>
      </Paper>
    </div>
  );
}

export default About;
