import React, { useEffect, useState } from "react";
import axios from "axios";
import { Typography, Paper, TextField, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import SearchIcon from "@mui/icons-material/Search";
import { ResponseCodes, getApiUrl } from "../utils/network-utils";
import BasicTableWithSort from "../components/BasicTableWithSort";
import { getFormattedDate } from "../utils/DateTime";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import useCustomStyles from "../styles/customStyles";
import { useAuth } from "../context/AuthContext";

const useStyles = makeStyles((theme) => ({
  heading: {
    marginBottom: theme.spacing(2),
    width: "95%",
  },
  searchInput: {
    width: "100%",
  },
  searchButton: {
    marginTop: theme.spacing(2),
  },
  searchResults: {
    width: "85%",
    padding: theme.spacing(3),
  },
  list: {
    //maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    overflow: "auto",
    maxHeight: 500,
  },
  link: {
    backgroundColor: "inherit",
    padding: 0,
    textDecoration: "none",
  },
}));

function Search() {
  const { isAuthenticated } = useAuth();
  const location = useLocation();
  const isHomePage = location.pathname === "/";
  const title = isHomePage ? "Homepage" : "Search";
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: title,
    });
    // eslint-disable-next-line
  }, []);
  const MIN_SEARCH_SIZE = 4;
  const classes = useStyles();
  const customClasses = useCustomStyles();
  const [searchResults, setSearchResults] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [tableData, setTableData] = useState([]);
  const [error, setError] = useState("");
  const apiUrl = getApiUrl() + "individual/search";

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);

    // Validate the input and set the error state accordingly
    if (value.length < MIN_SEARCH_SIZE) {
      setError("Search term must be at least 3 characters");
    } else {
      setError("");
    }
  };

  // If the user clicks on the search button
  // execute the search
  const handleSearchSubmit = () => {
    if (searchTerm.length < MIN_SEARCH_SIZE) {
      setError("Search term must be at least 3 characters");
    } else {
      setError("");
      executeSearch(searchTerm);
    }
  };

  // If the user hits the return key execute the search
  const onKeyPress = (event) => {
    if (event.keyCode === 13) {
      setSearchTerm(event.target.value);
      handleSearchSubmit();
    }
  };

  const executeSearch = (searchToken) => {
    ReactGA.event({
      category: "search-action",
      action: "click-search-button",
      label: "submit-search", // optional
    });

    const params = new URLSearchParams([["value", searchToken]]);

    axios
      .get(apiUrl, {
        params,
        withCredentials: true, // Enable sending cookies
      })
      .then((response) => {
        if (response?.data?.status_code === ResponseCodes.SUCCESS) {
          setSearchResults(response.data.data);
          //console.log("Search results: " + JSON.stringify(response.data.data));

          const tempData = response.data.data
            ? response.data.data.map((item) => ({
                id: item.individual_id,
                name_prefix: item.name_prefix,
                first_name: item.first_name,
                middle_name: item.middle_name,
                last_name: item.last_name,
                date_of_birth: getFormattedDate(
                  item.birth_year,
                  item.birth_month,
                  item.birth_day
                ),
                alias: item.alias,
              }))
            : [];

          setTableData(tempData);
        }
      });
  };

  const headCells = [
    { id: "name_prefix", label: "Title" },
    { id: "first_name", label: "First Name" },
    { id: "middle_name", label: "Middle Name" },
    { id: "last_name", label: "Last Name" },
    { id: "alias", label: "Alias" },
    { id: "date_of_birth", label: "Date of Birth" },
  ];

  if (isAuthenticated) {
    headCells.push({ id: "actions", label: "Actions" });
  }

  return (
    <div className={customClasses.root}>
      {isHomePage && (
        <Paper className={customClasses.headingPaper}>
          <Typography variant="heading1">About ceylontamils.com</Typography>
          <div>
            <Typography variant="bodyText">
              This website attempts to collate the genealogies legends and histories of
              Tamils whose ancestors originated in Sri Lanka, formerly Ceylon. It has been
              developed and maintained on a volunteer basis since 2003 by a small team of
              volunteers, to help family members establish their relationship to each
              other.
            </Typography>
            <Typography variant="bodyText">
              The presence of an individual or family on this site does not indicate that
              they are, or identify as being, a member of the Ceylon Tamil community but
              are linked in some way to people who consider themselves Ceylon Tamils.
            </Typography>
          </div>
        </Paper>
      )}

      <Paper className={customClasses.contentPaper}>
        <Typography variant="h4" className={classes.heading}>
          Search for a name:
        </Typography>
        <TextField
          className={classes.searchInput}
          variant="outlined"
          id="searchInput"
          onKeyDown={onKeyPress}
          onChange={handleSearchChange}
          label="Name Search"
          value={searchTerm}
          error={Boolean(error)}
          helperText={error}
          autoFocus={!isHomePage ? true : undefined}
        />

        <Button
          className={classes.searchButton}
          variant="contained"
          color="primary"
          startIcon={<SearchIcon />}
          onClick={handleSearchSubmit}
        >
          Search
        </Button>
      </Paper>

      {!searchResults && (
        <Paper className={classes.searchResults}>
          <Typography variant="h6">
            Unable to find any search results for {searchTerm}.
          </Typography>
        </Paper>
      )}

      {tableData.length > 0 && (
        <BasicTableWithSort
          headCells={headCells}
          tableData={tableData}
          isAuthenticated={isAuthenticated}
        />
      )}
    </div>
  );
}

export default Search;
