import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Drawer from "@mui/material/Drawer";
import MenuIcon from "@mui/icons-material/Menu";
import iconImage from "../images/palm.png";
import { Link } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { useAuth } from "../context/AuthContext";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => {
  return {
    root: {
      marginBottom: "7rem",
      position: "relative",
    },
    navButton: {
      textDecoration: "none",
      color: "#FFF",
    },
    subNavButton: {
      textDecoration: "none",
      color: "#fff",
      width: "10rem",
      height: "1rem",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },
    toolbar: {
      backgroundColor: "#35363A",
    },
    logo: {
      height: 30,
      marginRight: 8,
    },
    title: {
      flexGrow: 1,
      fontSize: "1.5rem",
      fontFamily: "Ubuntu",
      fontWeight: "550",
      letterSpacing: "0.1rem",
      marginLeft: theme.spacing(2),
      [theme.breakpoints.down("sm")]: {
        fontSize: "1rem",
        height: 20,
      },
    },
    menuButton: {
      [theme.breakpoints.down("sm")]: {
        marginLeft: "auto", // Align the menu button to the right on phones
      },
    },
    list: {
      display: "flex",
    },
    item: {
      borderRadius: 10,
      paddingRight: theme.spacing(2),
    },
    drawerPaper: {
      width: drawerWidth,
    },
    // New toolbar for authenticated users
    authenticatedToolbar: {
      //backgroundColor: "#ACADAE",
      backgroundColor: theme.palette.primary.main,
      display: "flex",
      justifyContent: "center",
      marginTop: "-4rem",
      width: "100%",
      position: "fixed",
      zIndex: theme.zIndex.appBar + 1,
      //height: "1rem",
      minHeight: "2.25rem",

      padding: 0,
    },
    subMenuList: {
      display: "flex",
      padding: 0,
    },
    subMenuListItem: {
      padding: 0,
    },
    marginBottomAuth: {
      marginBottom: "8rem",
    },
    marginBottomUnauth: {
      marginBottom: "6rem",
    },
    "@media (max-width: 600px)": {
      root: {
        marginBottom: "5.5rem",
      },
    },
  };
});

const CustomAppBar = (props) => {
  const { isAuthenticated } = useAuth();
  const isPhone = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const classes = useStyles();
  const updateSelected = (index) => {};

  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  useEffect(() => {}, [isAuthenticated]);

  function getAllNavMenuItems(isPhone, loggedIn) {
    let menuItems = [
      {
        name: "Home",
        linkTo: "/",
      },
      {
        name: "Search",
        linkTo: "/search",
      },
      {
        name: "Contact",
        linkTo: "/contact",
      },
    ];
    if (!loggedIn) {
      menuItems = [
        ...menuItems,
        {
          name: "Login",
          linkTo: "/login",
        },
      ];
    }

    return menuItems;
  }

  function getOneNavMenuItem(item, index) {
    return (
      <ListItem
        className={classes.item}
        key={"navItem-" + index}
        component={Link}
        to={item.linkTo}
        onClick={updateSelected}
      >
        <ListItemText primary={item.name} className={classes.navButton} />
      </ListItem>
    );
  }
  // Conditionally apply styles based on the isAuthenticated state
  const rootClassName = [
    classes.root,
    isAuthenticated ? classes.marginBottomAuth : classes.marginBottomUnauth,
  ].join(" ");

  return (
    <div className={rootClassName}>
      <AppBar>
        <Toolbar className={classes.toolbar}>
          <Link to={"/"}>
            <IconButton color="inherit" aria-label="home">
              <img src={iconImage} alt="Logo" className={classes.logo} />
            </IconButton>
          </Link>
          <Typography className={classes.title}>Ceylon Tamils Genealogy</Typography>
          {!isPhone && (
            <List className={classes.list}>
              {getAllNavMenuItems(isPhone, isAuthenticated).map((item, index) =>
                getOneNavMenuItem(item, index)
              )}
            </List>
          )}
          {isPhone && (
            <IconButton
              className={classes.menuButton}
              edge="end" // Align the icon to the right
              color="inherit"
              aria-label="menu"
              onClick={handleDrawerOpen}
            >
              <MenuIcon />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>

      {/* Drawer for mobile devices */}
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={handleDrawerClose}
        classes={{ paper: classes.drawerPaper }}
      >
        <List>
          {getAllNavMenuItems(isPhone, isAuthenticated).map((item, index) => (
            <ListItem
              key={index}
              component={Link}
              to={item.linkTo}
              onClick={handleDrawerClose}
            >
              <ListItemText primary={item.name} />
            </ListItem>
          ))}
        </List>
      </Drawer>
      {/* Authenticated users toolbar */}
      {isAuthenticated && (
        <Toolbar className={classes.authenticatedToolbar}>
          <List className={classes.subMenuList}>
            <ListItem
              component={Link}
              to="/dashboard"
              className={classes.subMenuListItem}
            >
              <ListItemText primary="Dashboard" className={classes.subNavButton} />
            </ListItem>
            <ListItem
              component={Link}
              to="/create-individual"
              className={classes.subMenuListItem}
            >
              <ListItemText
                primary="Create Individual"
                className={classes.subNavButton}
              />
            </ListItem>
            <ListItem
              component={Link}
              to="/create-family"
              className={classes.subMenuListItem}
            >
              <ListItemText primary="Create Family" className={classes.subNavButton} />
            </ListItem>
            <ListItem component={Link} to="/logout" className={classes.subMenuListItem}>
              <ListItemText primary="Logout" className={classes.subNavButton} />
            </ListItem>
          </List>
        </Toolbar>
      )}
    </div>
  );
};

export default CustomAppBar;
