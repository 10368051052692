const ACCESS_COOKIE_NAME = "CeylonTamilsAccess";
const REFRESH_COOKIE_NAME = "CeylonTamilsRefresh";

// Expires the access token and refresh token cookies
export function clearTokenCookies() {
  //console.log("Clearing cookies.");
  document.cookie =
    ACCESS_COOKIE_NAME +
    "=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=.www.ceylontamils.com;";
  document.cookie =
    REFRESH_COOKIE_NAME +
    "=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/api/index.php/auth; domain=.www.ceylontamils.com;";
}

// Returns true if the access token cookie exists
// This does not validate the token
export function getAccessToken() {
  return getCookie(ACCESS_COOKIE_NAME);
}

// Returns the value for given cookie name
function getCookie(cookieName) {
  const myCookie = document.cookie
    .split("; ")
    .find((cookie) => cookie.startsWith(`${cookieName}=`));
  return myCookie;
}
