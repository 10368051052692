import React from "react";
import { ThemeProvider, createTheme, StyledEngineProvider } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { useMediaQuery } from "@mui/material";

import "@fontsource/roboto";
import CustomAppBar from "./navigation/CustomAppBar";
import Routes from "./navigation/Router";
import { grey } from "@mui/material/colors";
import { BrowserRouter as Router } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import ReactGA from "react-ga4";
import { AuthProvider } from "./context/AuthContext";

const applyResponsiveStyles = (theme, isSmallScreen) => {
  return createTheme({
    ...theme,
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            //margin: "1rem 1rem 1rem 0",
            width: "100%",
            marginTop: "1.25rem",
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            //width: "auto",
            marginTop: "1.25rem",
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Adjust the values as needed
            // boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.3)",
            padding: (theme) => theme.spacing(2), // Add padding as needed
          },
        },
      },
    },
    spacing: 8,
    typography: {
      // ...theme.typography,
      fontSize: 14,
      color: "#000",
      h4: {
        fontSize: 20,
      },
      heading1: {
        marginBottom: "20px",
        fontSize: isSmallScreen ? "1rem" : "1.22rem",
        //fontWeight: "bold",
        color: "#35363A",
      },
      heading2: {
        fontSize: isSmallScreen ? "0.9rem" : "1rem",
        fontWeight: "bold",
        color: "#35363A",
      },
      bodyText: {
        fontSize: isSmallScreen ? "0.8rem" : "1rem",
        color: "#35363A",
      },
      smallText: {
        fontSize: isSmallScreen ? "0.4rem" : "0.6rem",
        color: "#35363A",
      },
    },
    palette: {
      primary: {
        main: "#2196f3", // Blue for primary elements
      },
      secondary: {
        main: "#ff4081", // Pink for secondary elements
      },
      background: {
        default: grey[200], // Light grey for the default background
      },
      headerRow: {
        main: "#e0e0e0", // Light grey for the header row background in the table
      },
      hoverRow: {
        main: "#e0f7fa", // Light blue for row hover state
      },
      searchInput: {
        main: "#fff", // White for the search input background
      },
      searchInputText: {
        main: "#333", // Dark grey for the search input text
      },
      focusedSearchInput: {
        main: "#64b5f6", // Light blue for focused search input
      },
      error: {
        main: "#f44336", // Red for error states
      },
      boy: {
        main: "#3498db", // blue
      },
      girl: {
        main: "#ff6b81", // pink
      },
      occupation: {
        main: "#61c14e", // green
      },
      marriage: {
        main: "#ffdd47", // yellow
      },
      prefix: {
        main: "#4b0082",
      },
      death: {
        main: "#8a2be2", // purple
      },
      default: {
        main: "#bdbdbd", // grey
      },
      action: {
        disabledBackground: "#e0e0e0", // Light gray background
        disabled: "#333", // Dark gray text
      },
    },
  });
};

const useStyles = makeStyles((theme) => ({
  // necessary for content to be below app bar
  content: {
    backgroundColor: grey[200], // main content background
    padding: "0 2rem 2rem 2rem",
  },
}));

ReactGA.initialize("G-9NDD2SW0CE");

function App() {
  const classes = useStyles();
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const theme = applyResponsiveStyles(createTheme(), isSmallScreen);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AuthProvider>
          <Router>
            <CustomAppBar />
            <main className={classes.content}>
              <div className={classes.toolbar} />
              <Routes />
            </main>
          </Router>
        </AuthProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
