import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Typography, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ReactGA from "react-ga4";
import axios from "axios";
import { getApiUrl } from "../utils/network-utils";
import useCustomStyles from "../styles/customStyles";

const useStyles = makeStyles((theme) => ({
  individualPaper: {
    width: "95%",
    position: "relative",
    padding: theme.spacing(3),
    //textAlign: 'center',
    alignItems: "center",
    overflow: "hidden", // Hide overflowing content
    marginBottom: theme.spacing(3),
  },
  individualInfo: {
    fontSize: "4rem",
    position: "relative",
    textAlign: "center",
  },
  name: {
    fontSize: "1.7rem",
    fontWeight: "575",
  },
  prefix: {
    fontSize: "1.7rem",
    fontWeight: "450",
    color: theme.palette.prefix.main,
  },

  "@media (max-width: 800px)": {
    individualPaper: {
      width: "100%",
    },
    background: {
      height: "100%",
    },
    nodeName: {
      fontSize: theme.spacing(3),
    },
    prefix: {
      fontSize: theme.spacing(3),
    },
  },
}));

function Pedigree() {
  const classes = useStyles();
  const customClasses = useCustomStyles();
  const { individualId } = useParams();
  const [individual, setIndividual] = useState();

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: "Pedigree",
    });

    // Get individual details
    const fetchIndividualData = async (id) => {
      // Define the API URL
      const apiUrl = getApiUrl() + "individual/get";
      const params = new URLSearchParams([["id", id]]);

      axios
        .get(apiUrl, { params, withCredentials: true })
        .then((response) => {
          if (response.data.status_code != null && response.data.status_code === 0) {
            var data = response.data.data[0];
            setIndividual(data);
          }
        })
        .catch((error) => {
          // Handle errors
          console.error("Error fetching data:", error);
        });
    };

    fetchIndividualData(individualId);
  }, [individualId]);

  // const genealogyData = [
  //   {
  //     name: "Tishan Mills",
  //     children: [
  //       {
  //         name: "Samuel Mills",
  //         children: [{ name: "Cyrus Mills" }, { name: "Joyce Rajaratnam" }],
  //       },
  //       {
  //         name: "Olive Sinnatamby",
  //         children: [{ name: "Arthur Sinnatamby" }, { name: "Mary Manikkawasagan" }],
  //       },
  //     ],
  //   },
  // ];

  // const generateChart = (genealogyData, lastLevel) => {
  //   const level = lastLevel + 1;
  //   return (
  //     <>
  //       {genealogyData.map((item, index) => (
  //         <>
  //           <li className="card" key={`card${index}${level}`}>
  //             {item.name}
  //             {item.children?.length && generateChart(item.children, level)}
  //           </li>
  //         </>
  //       ))}
  //     </>
  //   );
  // };

  return (
    <div>
      {individual && (
        <div className={customClasses.root}>
          <Paper className={customClasses.headingPaper}>
            <div className={classes.individualInfo}>
              <Typography variant="h5">
                <span className={classes.prefix}>{individual.name_prefix}</span>
                <span className={classes.name}>
                  {" "}
                  {individual.first_name} {individual.middle_name} {individual.last_name}
                </span>{" "}
                <span className={classes.prefix}>{individual.name_suffix}</span>
                {individual.alias && (
                  <span className={classes.prefix}> ({individual.alias})</span>
                )}
              </Typography>
            </div>
          </Paper>
          <Paper className={customClasses.contentPaper}>Pedigree chart goes here</Paper>
        </div>
      )}
    </div>
  );
}

export default Pedigree;
